@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

h2 {
  font-size: 2rem;
  font-weight: 700;
  padding-bottom: 15px;
}
.input {
  @apply w-full p-2 border border-[#CBD6E2] rounded bg-[#F5F8FA];
}
